<template>
  <div class="team">
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text"> تعديل الراتب </h1>
      <v-form v-model="isFormValid">
        <v-row class="mt-5">
          <!-- name -->
          <v-col md="6" sm="6" cols="12">
            <v-autocomplete v-model="data.account_id" :rules="rules.account_id" :loading="accountLoading"
              :item-text="item => `${item.account_name} ( ${getAccountTypeInArabic(item.account_type)} )`"
              :items="accountData" item-value="_id" clearable outlined dense label="اسم الموظف"
              @click:clear="data.account_id = null"></v-autocomplete>
          </v-col>
          <!-- amount -->
          <v-col md="6" sm="6" cols="12">
            <v-text-field v-model="data.amount" @keypress="isNumber($event)" dense label="الراتب" outlined>
            </v-text-field>
          </v-col>
          <!-- additional -->
          <v-col md="12" sm="6" cols="12">
            <!-- Add purchased products -->
            <div class="add-products-form">
              <h2 class="mb-2">الزيادات</h2>
              <div v-for="(additionalItem, additionalItemIndex) in data.additional" :key="additionalItemIndex"
                class="single-product-form">

                <v-card outlined class="d-flex">
                  <!-- Left Form -->
                  <v-row>
                    <v-col md="3" sm="6" cols="12">
                      <v-autocomplete v-model="additionalItem.counting_service_salary"
                        :loading="actionsAdditionalLoading" :items="actionsAdditionalData" item-text="name"
                        item-value="_id" clearable outlined dense label="نوع الزيادة"></v-autocomplete>
                    </v-col>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="additionalItem.price" @keypress="isNumber($event)" dense label="المبلغ"
                        outlined></v-text-field>
                    </v-col>
                    <v-col md="6" sm="6" cols="12">
                      <v-textarea v-model="additionalItem.note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>


                  <!-- Item Actions -->
                  <div class="d-flex flex-column item-actions rounded-0 pa-1">
                    <v-btn icon small @click="data.additional.splice(additionalItemIndex, 1)">
                      <v-icon size="20">
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </div>
              <div class="card_action">
                <v-btn color="primary" class="mt-4 ml-11" outlined @click="addNewItem">
                  اضافة زيادة اخرى
                </v-btn>
                <h3 class="mt-4">مبلغ الزيادات الكلي: {{ numberWithComma(allAdditional ? allAdditional : 0) + " " +
                    currencySymbol
                }}
                </h3>
              </div>
            </div>
          </v-col>

          <!-- discounts -->
          <v-col md="12" sm="6" cols="12">
            <!-- Add purchased products -->
            <div class="add-products-form">
              <h2 class="mb-2">الاستقطاعات</h2>
              <div v-for="(discountItem, discountItemIndex) in data.discounts" :key="discountItemIndex"
                class="single-product-form">

                <v-card outlined class="d-flex">
                  <!-- Left Form -->
                  <v-row>
                    <v-col md="3" sm="6" cols="12">
                      <v-autocomplete v-model="discountItem.counting_service_salary" :loading="actionsDiscountLoading"
                        :items="actionsDiscountData" item-text="name" item-value="_id" clearable outlined dense
                        label="نوع الاستقطاع"></v-autocomplete>
                    </v-col>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="discountItem.price" @keypress="isNumber($event)" dense label="المبلغ"
                        outlined></v-text-field>
                    </v-col>
                    <v-col md="6" sm="6" cols="12">
                      <v-textarea v-model="discountItem.note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>


                  <!-- Item Actions -->
                  <div class="d-flex flex-column item-actions rounded-0 pa-1">
                    <v-btn icon small @click="data.discounts.splice(discountItemIndex, 1)">
                      <v-icon size="20">
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </div>
              <div class="card_action">
                <v-btn color="primary" class="mt-4 ml-11" outlined @click="addNewItemDiscount">
                  اضافة استقطاع اخر
                </v-btn>
                <h3 class="mt-4">مبلغ الاستقطاعات الكلي: {{ numberWithComma(allDiscounts ? allDiscounts : 0) + " " +
                    currencySymbol
                }}
                </h3>
              </div>
            </div>
          </v-col>
          <!-- <v-autocomplete v-model="data.account_id" :loading="actionsDiscountLoading" :items="actionsDiscountData"
            item-text="name" item-value="_id" clearable outlined dense label="نوع الاستقطاع"></v-autocomplete> -->

          <!-- lectures -->
          <v-col md="12" sm="6" cols="12">
            <div class="add-products-form">
              <h2 class="mb-2">المحاظرات</h2>
              <div class="single-product-form">
                <v-card outlined class="d-flex">
                  <v-row>
                    <!-- <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="data.lectures_number" @keypress="isNumber($event)" dense
                        label="عدد المحاظرات" outlined>
                      </v-text-field>
                    </v-col> -->
                    <v-col md="4" sm="6" cols="12">
                      <v-text-field v-model="data.all_lectures_price" @keypress="isNumber($event)" dense
                        label="مبلغ المحاظرات الكلي" outlined>
                      </v-text-field>
                    </v-col>
                    <v-col md="8" sm="6" cols="12">
                      <v-textarea v-model="data.lectures_note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>

                </v-card>
              </div>
            </div>
          </v-col>


          <!-- lectures -->
          <v-col md="12" sm="6" cols="12">
            <div class="add-products-form">
              <h2 class="mb-2">المراقبات</h2>
              <div class="single-product-form">
                <v-card outlined class="d-flex">
                  <v-row>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="data.watch_number" @keypress="isNumber($event)" dense label="عدد المراقبات"
                        outlined>
                      </v-text-field>
                    </v-col>
                    <v-col md="3" sm="6" cols="12">
                      <v-text-field v-model="data.per_watch_price" @keypress="isNumber($event)" dense
                        label="مبلغ المراقبة الواحدة" outlined>
                      </v-text-field>
                    </v-col>
                    <v-col md="6" sm="6" cols="12">
                      <v-textarea v-model="data.watch_note" outlined label="الملاحظة" rows="3" hide-details="auto"
                        placeholder="الملاحظة"></v-textarea>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-col>
          <!-- birthday -->
          <v-col cols="12">
            <v-menu v-model="menuAccountBirthday" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="data.payment_date" :rules="rules.payment_date" dense label="تاريخ الراتب"
                  outlined readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="data.payment_date" @input="menuAccountBirthday = false"></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" class="text-center">
            <h1 class="mb-5">الراتب المستحق: {{ deserve_amount + " " + currencySymbol }}</h1>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-center mt-2">
        <v-btn :loading="addBtnLoading" :disabled="!isFormValid" color="primary" @click="submitEdit()"> تعديل </v-btn>
        <v-btn class="mr-5" @click="cancelAdd()"> الغاء </v-btn>
      </div>
    </v-card>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api';
import numberWithComma from '@/constant/number';
import { mdiClose, mdiCogOutline } from '@mdi/js';

export default {
  data() {
    return {

      allAdditional: null,

      allDiscounts: null,

      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
      },

      isFormValid: false,


      discountsItems: [
        {
          counting_service_salary: null,
          price: null,
          note: null,
        },
      ],

      additionalItems: [
        {
          counting_service_salary: null,
          price: null,
          note: null,
        },
      ],

      accountLoading: false,

      accountData: [],

      actionsAdditionalData: [],

      actionsAdditionalLoading: false,

      actionsDiscountData: [],

      actionsDiscountLoading: false,

      data: {
        _id: null,
        account_id: null,
        amount: null,
        additional: [],
        discounts: [],
        all_lectures_price: null,
        // lectures_number: null,
        // per_lectures_price: null,
        lectures_note: null,
        watch_number: null,
        per_watch_price: null,
        watch_note: null,
        payment_date: null,
      },

      deserve_amount: 0,


      rules: {
        account_id: [value => !!value || 'اسم الموظف مطلوب'],
        payment_date: [value => !!value || 'يجب اختيار تاريخ تسليم الراتب'],
      },

      account_disableItems: [
        { text: 'مفعل', value: false },
        { text: 'متوقف', value: true },
      ],

      menuAccountBirthday: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      accountLoading: false,

      addBtnLoading: false,

      currencySymbol: null,
    }
  },

  created() {
    const results = JSON.parse(localStorage.getItem('results'))
    this.currencySymbol = results.school_currency === "US" ? "$" : results.school_currency

    this.getEmployee()
    this.getDataActionsAdditional()
    this.getDataActionsDiscount()
    this.data = localStorage.getItem('salaryEditItem')
    this.data = JSON.parse(this.data)
  },


  watch: {
    'data.amount': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },

    'data.all_lectures_price': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },

    // 'data.per_lectures_price': {
    //   handler() {
    //     this.calculateDeserveAmount()
    //     // this.paymentChange()
    //   },
    // },

    'data.watch_number': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },


    'data.per_watch_price': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
    },

    'data.additional': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
      deep: true
    },

    'data.discounts': {
      handler() {
        this.calculateDeserveAmount()
        // this.paymentChange()
      },
      deep: true
    },
  },

  methods: {
    async submitEdit() {
      this.addBtnLoading = true


      const response = await Api.editEmployeeSalary({
        amount: this.data.amount,
        discounts: this.data.discounts,
        additional: this.data.additional,
        additional: this.data.additional,
        all_lectures_price: +(this.data.all_lectures_price),
        lectures_note: this.data.lectures_note,
        per_watch_price: this.data.per_watch_price,
        watch_number: this.data.watch_number,
        watch_note: this.data.watch_note,
        payment_date: this.data.payment_date,
        _id: this.data._id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addBtnLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.addBtnLoading = false
        this.showDialogfunction(response.data.results, 'primary')
        this.$router.replace('/salariesShow')
      }
    },

    async getEmployee() {
      this.accountLoading = true

      const response = await Api.getEmployee()

      if (response.status === 401) {
        this.accountLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.accountLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.accountLoading = false
        this.accountData = response.data.results
      }
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    addNewItem() {
      this.data.additional.push({
        counting_service_salary: '',
        price: null,
        note: '',
      })
    },

    addNewItemDiscount() {
      this.data.discounts.push({
        counting_service_salary: '',
        price: null,
        note: '',
      })
    },

    async getDataActionsAdditional() {
      this.actionsAdditionalLoading = true

      const response = await Api.getActions("زيادة")

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.actionsAdditionalLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.actionsAdditionalLoading = false
        this.actionsAdditionalData = response.data.results
      }
    },

    async getDataActionsDiscount() {
      this.actionsDiscountLoading = true

      const response = await Api.getActions("استقطاع")

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.actionsDiscountLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.actionsDiscountLoading = false
        this.actionsDiscountData = response.data.results
      }
    },


    async calculateDeserveAmount() {
      const allAdditional = await this.calculateAllAdditional();
      const allDiscounts = await this.calculateAllDiscounts();

      this.allAdditional = allAdditional
      this.allDiscounts = allDiscounts

      this.deserve_amount = (+(this.data.amount) ? +(this.data.amount) : 0) + (allAdditional ? allAdditional : 0) + (+(this.data.all_lectures_price) ? +(this.data.all_lectures_price) : 0) + ((+(this.data.watch_number) ? +(this.data.watch_number) : 0) * (+(this.data.per_watch_price) ? +(this.data.per_watch_price) : 0)) - (allDiscounts ? allDiscounts : 0)


      this.deserve_amount = numberWithComma(this.deserve_amount)

    },

    async calculateAllAdditional() {
      let results = 0;
      for await (const iterator of this.data.additional) {
        results += +(iterator.price)
      }
      return results;
    },

    async calculateAllDiscounts() {
      let results = 0;
      for await (const iterator of this.data.discounts) {
        results += +(iterator.price)
      }
      return results;
    },


    cancelAdd() {
      window.history.back()
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    getAccountTypeInArabic(account_type) {
      switch (account_type) {
        case "accountant": return "محاسب";
        case "manager": return "مدير";
        case "driver": return "سائق";
        case "teacher": return "استاذ";
        case "nightGard": return "حارس ليلي";
        case "gard": return "حارس";
        case "marketEmployee": return "موظف الماركت";
        case "trackingEmployee": return "مسؤول المتابعة";
        case "informationEmployee": return "موظف الاستعلامات";
        case "healthOrganizer": return "منسق صحي";
        case "assistance": return "سكرتير";
        case "gymTrainer": return "مدرب الجم";
        case "marketingManger": return "مدير التسويق";

        default: return "موظف";
      }
    },

    numberWithComma,
  },
}
</script>

<style scoped>
.card_action {
  display: flex;
  align-content: center;
}
</style>
